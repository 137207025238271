import React, { useContext, useEffect, useRef } from "react";
import { MainPlayerContext } from "../Context/MainPlayerContext";

function Canvas({ canvas }) {
  console.log("Draw render");
  const mainPlayer = useContext(MainPlayerContext);
  const canvasRef = useRef();

  useEffect(() => {
    if (canvas !== "null") {
      draw(canvas, canvasRef.current);
    }
  }, [canvas]);

  function draw(actions, canvas) {
    const ctx = canvas.getContext("2d");
    const actionsArray = JSON.parse(actions);

    for (let i = 0; i < actionsArray.length; ++i) {
      setTimeout(() => {
        if (actionsArray[i][0] === "lineTo") {
          ctx.lineTo(actionsArray[i][1], actionsArray[i][2]);
        } else if (actionsArray[i][0] === "moveTo") {
          ctx.moveTo(actionsArray[i][1], actionsArray[i][2]);
        } else if (actionsArray[i][0] === "beginPath") {
          ctx.beginPath();
        } else if (actionsArray[i][0] === "stroke") {
          ctx.stroke();
        }
      }, i * 3);
    }
  }

  return (
    <div>
      <canvas
        style={{ border: "1px solid black" }}
        ref={canvasRef}
        width={800}
        height={600}
      />
    </div>
  );
}

export default Canvas;
