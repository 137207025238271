import React from "react";
import { useContext } from "react";

function Entry() {
  const setOnAuth = useContext(AuthContext);
  console.log(setOnAuth);

  function handleSubmit(e) {
    e.preventDefault();
    const username = e.target.elements["username"].value;
    !!localStorage.getItem("id") || localStorage.setItem("id", username);
    setOnAuth(!!username);
  }

  return (
    <div className="entry-form" onSubmit={handleSubmit}>
      <form>
        <p>
          <label htmlFor="username">Nickname</label>
          <br />
          <input type="text" name="username" required />
        </p>
        <button type="submit">Enter</button>
      </form>
    </div>
  );
}

export default Entry;
