import React from "react";
import { useContext } from "react";
import { TimerContext } from "../Context/TimerContext";

function Timer() {
  const timer = useContext(TimerContext);
  return <div className="timer">{timer >= 1 ? <p>{timer}</p> : null}</div>;
}

export default Timer;
