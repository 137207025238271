import React, { useContext, useEffect, useRef } from "react";
import Brush from "../tools/Brush";
import { MainPlayerContext } from "../Context/MainPlayerContext";
import { SocketContext } from "../Context/SocketContext";
import { ThemeContext } from "../Context/ThemeContext";

function Draw() {
  console.log("Draw render");
  const mainPlayer = useContext(MainPlayerContext);
  const theme = useContext(ThemeContext);
  const socket = useContext(SocketContext);
  const canvasRef = useRef();
  console.log("theme", theme);
  if (theme === "null") {
    socket.send(JSON.stringify({ method: "requestMessage", data: mainPlayer }));
  }

  useEffect(() => {
    new Brush(canvasRef.current, socket);
  }, []);

  return (
    <div>
      <h1>{theme !== "null" ? theme : "Draw what you want"}</h1>
      <canvas
        style={{ border: "1px solid black" }}
        ref={canvasRef}
        width={800}
        height={600}
      />
    </div>
  );
}

export default Draw;
