import React from "react";
import usePartySocket from "partysocket/react";
import { useState } from "react";
import Layout from "./Layout";
import { MainPlayerContext } from "../Context/MainPlayerContext.js";
import { useContext } from "react";
import Timer from "./Timer.jsx";
import Theme from "./Theme.jsx";
import Draw from "./Draw.jsx";
import { SocketContext } from "../Context/SocketContext.js";
import { TimerContext } from "../Context/TimerContext.js";
import { ThemeContext } from "../Context/ThemeContext.js";
import { CanvasContext } from "../Context/CanvasContext.js";
import Describe from "./Describe.jsx";
import { FinalContext } from "../Context/FinalContext.js";
import Final from "./Final.jsx";
import Canvas from "./Canvas.jsx";

const PhaseContext = React.createContext();

function Room({ children }) {
  const [mainPlayer, setMainPlayer] = useState(false);
  const [timer, setTimer] = useState(0);
  const [setPhase, setPlayerList, setArtList] = useContext(PhaseContext);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "null");
  const [canvas, setCanvas] = useState(
    localStorage.getItem("canvas") || "null"
  );
  const [finalContent, setFinalContent] = useState();
  const room = getRoomId();
  const id = String(localStorage.getItem("id"));

  const socket = usePartySocket({
    room: room,
    id: id,
    onMessage(evt) {
      let msg = JSON.parse(evt.data);
      console.log("Server send: ", msg.method, msg.data);
      switch (msg.method) {
        case "setMainPlayer": {
          setMainPlayer(true);
          break;
        }
        case "tick": {
          setTimer(msg.data);
          break;
        }
        case "phase": {
          setPhase(msg.data);
          break;
        }
        case "reset": {
          setTheme("null");
          setCanvas("null");
          localStorage.removeItem("theme");
          localStorage.removeItem("canvas");
          setTimer(0);
          setPhase(msg.data);
          break;
        }
        case "setPlayerList": {
          const playerList = msg.data.map((item, index) => {
            return <li key={index + "player"}>{item.id}</li>;
          });
          if (mainPlayer) {
            const artList = msg.data.map((item, index) => {
              return (
                <button
                  onClick={() => {
                    socket.send(
                      JSON.stringify({ method: `content`, data: item.id })
                    );
                  }}
                  key={index + "player1"}
                >
                  {item.id}
                </button>
              );
            });
            setArtList(artList);
          }

          setPlayerList(playerList);
          break;
        }
        case "message": {
          localStorage.setItem("theme", msg.data);
          setTheme(msg.data);
          break;
        }
        case "canvas": {
          localStorage.setItem("canvas", msg.data);
          setCanvas(msg.data);
          break;
        }
        case "content":
          const art = msg.data.map((item, index) => {
            if (item[1] === "message") {
              return <h2 key={index + "something"}>{item[0]}</h2>;
            } else {
              return <Canvas key={index + "something"} canvas={item[0]} />;
            }
          });
          setFinalContent(art);
          break;
      }
    },
  });
  return (
    <>
      <FinalContext.Provider value={finalContent}>
        <CanvasContext.Provider value={canvas}>
          <ThemeContext.Provider value={theme}>
            <TimerContext.Provider value={timer}>
              <SocketContext.Provider value={socket}>
                <MainPlayerContext.Provider value={mainPlayer}>
                  {children}
                </MainPlayerContext.Provider>
              </SocketContext.Provider>
            </TimerContext.Provider>
          </ThemeContext.Provider>
        </CanvasContext.Provider>
      </FinalContext.Provider>
    </>
  );
}

function CompoundComponent() {
  const [phase, setPhase] = useState("start");
  const [playerList, setPlayerList] = useState([]);
  const [artList, setArtList] = useState([]);

  console.log("Compound Component render");
  return (
    <>
      <PhaseContext.Provider value={[setPhase, setPlayerList, setArtList]}>
        <Room>
          <Layout />
          <Timer />
          {phase === "start" ? <p>{playerList}</p> : null}
          {phase === "phaseTheme" ? <Theme /> : null}
          {phase === "phaseDraw" ? <Draw /> : null}
          {phase === "phaseMessage" ? <Describe /> : null}
          {phase === "final" ? <p>{artList}</p> : null}
          {phase === "final" ? <Final /> : null}
        </Room>
      </PhaseContext.Provider>
    </>
  );
}

export function getRoomId() {
  const room = window.location.href;
  const ans = room.slice(room.indexOf("?") + 1);

  return ans;
}

export default CompoundComponent;
