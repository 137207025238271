import React from "react";
import { useContext } from "react";
import { AuthContext } from "../Context/AuthContext.js";
import { MainPlayerContext } from "../Context/MainPlayerContext.js";
import { SocketContext } from "../Context/SocketContext.js";

function Layout() {
  console.log("Layout render");
  const setOnAuth = useContext(AuthContext);
  const isMainPlayer = useContext(MainPlayerContext);
  const socket = useContext(SocketContext);

  function exit() {
    localStorage.removeItem("auth");
    localStorage.removeItem("id");
    setOnAuth(false);
  }

  function reset() {
    socket.send(JSON.stringify({ method: "reset" }));
  }

  function start() {
    socket.send(JSON.stringify({ method: "start" }));
  }

  return (
    <div className="layout">
      {isMainPlayer ? <button onClick={reset}>Reset</button> : <></>}
      {isMainPlayer ? <button onClick={start}>Start</button> : <></>}
      <button onClick={exit}>Rename</button>
      {/* <button onClick={reset}>Reset</button>
      <button onClick={start}>Start</button> */}
    </div>
  );
}

export default Layout;
