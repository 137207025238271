import React from "react";
import { useContext } from "react";
import { SocketContext } from "../Context/SocketContext.js";

function Theme() {
  console.log("Theme render");
  const socket = useContext(SocketContext);

  function handleClick() {
    socket.send(
      JSON.stringify({
        method: "message",
        data: `${document.getElementById("initialtext").value}`,
      })
    );
  }

  return (
    <div className="Theme">
      <h2>Theme</h2>
      <input id="initialtext" type="text"></input>
      <button onClick={handleClick}>Отправить</button>
    </div>
  );
}

export default Theme;
