import React, { useContext } from "react";
import { MainPlayerContext } from "../Context/MainPlayerContext";
import { SocketContext } from "../Context/SocketContext";
import { CanvasContext } from "../Context/CanvasContext";
import Canvas from "./Canvas";

function Describe() {
  console.log("Draw render");
  const mainPlayer = useContext(MainPlayerContext);
  const canvas = useContext(CanvasContext);
  const socket = useContext(SocketContext);

  console.log("@@@ canvas", canvas);
  if (canvas === "null") {
    socket.send(JSON.stringify({ method: "requestCanvas", data: mainPlayer }));
  }

  function handleClick() {
    socket.send(
      JSON.stringify({
        method: "message",
        data: `${document.getElementById("initialtext").value}`,
      })
    );
  }

  return (
    <div>
      {/* <canvas
        style={{ border: "1px solid black" }}
        ref={canvasRef}
        width={800}
        height={600}
      /> */}
      <Canvas canvas={canvas} />
      <input id="initialtext" type="text"></input>
      <button onClick={handleClick}>Отправить</button>
    </div>
  );
}

export default Describe;
