import React, { useContext, useEffect, useState } from "react";
import Canvas from "./Canvas";
import { FinalContext } from "../Context/FinalContext";

function Final() {
  console.log("Final render");
  const content = useContext(FinalContext);
  return <div>{content}</div>;
}

export default Final;
