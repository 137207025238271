import "./styles.css";
import Entry from "./components/Entry";
import { AuthContext } from "./Context/AuthContext.js";
import { createRoot } from "react-dom/client";
import React, { useContext, useState } from "react";
import CompoundComponent from "./components/CompoundComponent";

function App() {
  const [onAuth, setOnAuth] = useState(localStorage.getItem("id"));

  return (
    <>
      <AuthContext.Provider value={setOnAuth}>
        {!onAuth ? <Entry /> : null}
        {onAuth ? <CompoundComponent /> : null}
      </AuthContext.Provider>
    </>
  );
}

function Entry() {
  const setOnAuth = useContext(AuthContext);

  function handleSubmit(e) {
    e.preventDefault();
    const username = e.target.elements["username"].value;
    !!localStorage.getItem("id") || localStorage.setItem("id", username);
    setOnAuth(!!username);
  }

  return (
    <div className="entry-form" onSubmit={handleSubmit}>
      <form>
        <p>
          <label htmlFor="username">Nickname</label>
          <br />
          <input type="text" name="username" required />
        </p>
        <button type="submit">Enter</button>
      </form>
    </div>
  );
}

createRoot(document.getElementById("app")!).render(<App />);
